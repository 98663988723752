import React from 'react';
import {
  Container,
  Title,
  TitleContainer,
  Unit,
  GraphContainer,
  StatusContainer,
  StatusLabel,
  StatusHeader,
  GraphValues,
} from './styles';
import { BloodPressureProps } from '.';
import { getPressureStatus } from '../../../../../utilities/bloodPressureUtils';
import Colors from '../../../../../theme/colors';
import BloodPressureGraph from './BloodPressureGraph/BloodPressureGraph';

function BloodPressure({ systolic, diastolic, testType }: BloodPressureProps) {
  const status = getPressureStatus(systolic, diastolic);
  return (
    <Container status={status}>
      <StatusHeader>
        <TitleContainer>
          <Title color={Colors.Text.Heading} variant="BodyMediumSemiBold">
            {`Blood Pressure`}
          </Title>
          <Unit color={Colors.Text.Heading} variant="BodyMediumRegular">{`(mmHg/mmHg)`}</Unit>
        </TitleContainer>

        <StatusContainer>
          <StatusLabel status={status}>
            {status === 'SlightlyRaised' ? 'Slightly Raised' : status}
          </StatusLabel>
        </StatusContainer>
      </StatusHeader>
      <GraphContainer>
        <GraphValues
          color={Colors.Text.Heading}
          variant="BodyMediumSemiBold"
          style={{ alignSelf: 'flex-start' }}
        >{`Systolic (higher pressure)`}</GraphValues>
        <BloodPressureGraph
          systolic={systolic}
          diastolic={diastolic}
          status={status}
          testType={testType}
        />
        <GraphValues
          color={Colors.Text.Heading}
          variant="BodyMediumSemiBold"
        >{`Diastolic (lower pressure)`}</GraphValues>
      </GraphContainer>
    </Container>
  );
}

export default BloodPressure;
