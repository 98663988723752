import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Colors from '../../../../../theme/colors';
import { StatusLabelProps, HbA1cGraphColors, HbA1cStatus, HbA1CContainerProps } from '.';

const HbA1cContainedBackgroundColors = {
  Healthy: Colors.BiologicalResultColors.HealthyBackground,
  Raised: Colors.BiologicalResultColors.WarningBackground,
  High: Colors.BiologicalResultColors.UnhealthyBackground,
};

export const StatusBgColor = {
  Healthy: Colors.Status.Successful,
  Raised: Colors.Status.Warning,
  High: Colors.Main.Blood,
};

export const SecondaryPreventionStatusBgColor = {
  Healthy: Colors.Status.Successful,
  Raised: Colors.Status.Warning,
  High: Colors.Main.Blood,
};
export const SecondaryPreventionBgColor = {
  Healthy: Colors.Status.Successful,
  High: Colors.Main.Blood,
};

export const StatusTextColor = {
  Healthy: Colors.Main.Light,
  Raised: Colors.Main.Dark,
  High: Colors.Main.Light,
};
export const getGraphColors = (
  status: HbA1cStatus,
  isSecondaryPrevention?: boolean
): HbA1cGraphColors => ({
  valueLabelColor: StatusBgColor[status],
  valueLabelTextColor: StatusTextColor[status],
  colors: Object.values(!isSecondaryPrevention ? StatusBgColor : SecondaryPreventionBgColor),
});

const Container = styled(Box)<BoxProps & HbA1CContainerProps>((props) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  minHeight: '88px',
  padding: ['12px', '24px'],
  margin: ['4px', '0px'],
  marginTop: '8px',
  borderRadius: '10px',
  borderStyle: 'solid',
  borderWidth: '2px',
  backgroundColor: HbA1cContainedBackgroundColors[props.status],
  borderColor: StatusBgColor[props.status],
}));
const TitleContainer = styled(Box)({
  flex: 39,
  display: 'flex',
});

const Title = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  marginRight: 8,
});
const Unit = styled(Typography)({});

Title.defaultProps = {
  variant: 'BodySmallRegular',
  color: Colors.Text.Heading,
};

const BarGraphContainer = styled(Box)({ flex: 33 });

const StatusContainer = styled(Typography)({
  flex: 28,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
});

const StatusLabel = styled(Typography)<TypographyProps & StatusLabelProps>((props) => ({
  width: '86px',
  height: '26px',
  backgroundColor: StatusBgColor[props.status],
  color: StatusTextColor[props.status],
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'capitalize',
}));

StatusLabel.defaultProps = {
  variant: 'BodySmallBoldPoppins',
};

export { Container, Title, BarGraphContainer, StatusContainer, StatusLabel, Unit, TitleContainer };
