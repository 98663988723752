import React from 'react';
import { ColorBar } from './ColorBar';
import { GraphLabels, GraphLabel } from './GraphLabels';
import { ValueLabel } from './ValueLabel';
import Container from './styles';
import { BarGraphProps } from '.';
import { getDistanceInPercentages, getValueLabelOffset } from '../../utilities/utils';

function BarGraph({ value, showFirstAndLastLabel = true, points, colors }: BarGraphProps) {
  const sectionPercentages = getDistanceInPercentages(points);

  /**
   * maps points and sectionPercentages to label and absolute position (in percentage) pairs
   * e.g.
   * input: points=[2,4,12], sectionPercentages=[20, 80]
   * ouput: [{label: 12, position: 0}, {label: 4, position: 20}, {label: 12, position: 100}]
   */
  const allGraphLabels = sectionPercentages.reduce(
    (acc: Array<GraphLabel>, val, i) => {
      acc.push({ label: points[i + 1], position: val + acc[i].position });
      return acc;
    },
    [{ label: points[0], position: 0 }]
  );

  const graphLabels = showFirstAndLastLabel ? allGraphLabels : allGraphLabels.slice(1, -1);

  return (
    <Container>
      {value ? (
        <ValueLabel
          label={value.label}
          backgroundColor={value.bgColor}
          textColor={value?.labelColor}
          offsetPercentage={getValueLabelOffset(points, value.point)}
        />
      ) : null}
      <ColorBar sections={sectionPercentages} colors={colors} />
      <GraphLabels labels={graphLabels} />
    </Container>
  );
}

export default BarGraph;
