/* eslint-disable camelcase */
import React from 'react';
import MeasurementsProps from '.';
import Container from './styles';
import { BMI } from './BMI';
import HbA1C from './HbA1c/HbA1c';
import SectionHeader from '../SectionHeader/SectionHeader';
import BloodPressure from './BloodPressure/BloodPressure';

function Measurements({ bmiValue = 0, hbA1c, pressureValues, testType }: MeasurementsProps) {
  return (
    <Container>
      <SectionHeader icon="qrisk" title="Measurements" />
      <BMI bmiValue={bmiValue} />
      {hbA1c.hbA1cValue && (
        <HbA1C hbA1cValue={hbA1c.hbA1cValue} isSecondaryPrevention={hbA1c.isSecondaryPrevention} />
      )}
      {pressureValues.diastolic && pressureValues.systolic && (
        <BloodPressure
          systolic={pressureValues.systolic}
          diastolic={pressureValues.diastolic}
          testType={testType}
        />
      )}
    </Container>
  );
}

export default Measurements;
