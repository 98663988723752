import { HbA1CGraphParams } from '~/pages/TestListPage/TestResultModal/Measurements/HbA1c';

export const HbA1cGraphSignificantPoints = {
  domainStart: 5,
  breakpoints: [6, 6.5],
  domainEnd: 15,
  domainMax: 8,
};

export const HbA1cGraphSignificantPointsForSecondaryPrevention = {
  domainStart: 5,
  breakpoints: [6.5],
  domainEnd: 15,
  domainMax: 8,
};

/**
 * Calculates HbA1c status based on range the provided value lies in.
 * @param value HbA1c value
 * @returns HbA1c status
 */

export const getStatus = (value: number, isSecondaryPrevention?: boolean) => {
  if (!isSecondaryPrevention && value >= 6 && value <= 6.4) {
    return 'Raised';
  }
  if (value < 6.5 && isSecondaryPrevention) {
    return 'Healthy';
  }
  if (value < 6 && !isSecondaryPrevention) {
    return 'Healthy';
  }

  return 'High';
};

/**
 * helper method that performs value cutoff in case provided input is out of allowed range
 * and returns props needed for UI display
 * @param bmiValue real BMI input
 * @returns {HbA1cGraphParams}
 */

export const getGraphParams = (
  HbA1cValue: number,
  isSecondaryPrevention?: boolean
): HbA1CGraphParams => {
  const { domainStart, domainEnd, domainMax, breakpoints } = !isSecondaryPrevention
    ? HbA1cGraphSignificantPoints
    : HbA1cGraphSignificantPointsForSecondaryPrevention;
  // by default, graph is plotted from domainStart. In case the provided value is bellow it,
  // the start point is lowered as well in order to plot it.
  const startPoint = domainStart;

  if (HbA1cValue > domainEnd) {
    return {
      graphPoints: [startPoint, ...breakpoints, domainMax],
      resultLabel: `> ${domainEnd.toFixed(1)}`,
      resultValue: domainMax,
    };
  }
  if (HbA1cValue < domainStart) {
    return {
      graphPoints: [startPoint, ...breakpoints, domainMax],
      resultLabel: `${HbA1cValue.toFixed(1)}`,
      resultValue: startPoint,
    };
  }
  return {
    graphPoints: [startPoint, ...breakpoints, domainMax],
    resultLabel: HbA1cValue.toFixed(1),
    resultValue: HbA1cValue > domainMax ? domainMax : HbA1cValue,
  };
};

/**
 *
 * @param stringValue
 * @returns number
 */
export const parsePercentage = (stringValue: string) => {
  return parseFloat(stringValue.replace('%', ''));
};
