import { BloodPressureStatus } from '~/pages/TestListPage/TestResultModal/Measurements/BloodPressure';
/**
 *
 * @param systolic pressure value
 * @param diastolic pressure value
 * @returns BloodPressureStatus string
 */
export const getPressureStatus = (systolic: number, diastolic: number): BloodPressureStatus => {
  if (systolic >= 70 && systolic <= 170 && diastolic >= 40 && diastolic <= 100) {
    if (systolic >= 70 && systolic < 90 && diastolic >= 40 && diastolic < 60) {
      return 'Low';
    }
    if (systolic >= 70 && systolic <= 120 && diastolic >= 40 && diastolic <= 80) {
      return 'Healthy';
    }
    if (
      (systolic > 120 && systolic < 135 && diastolic >= 40 && diastolic < 85) ||
      (systolic >= 70 && systolic < 135 && diastolic > 80 && diastolic < 85)
    ) {
      return 'SlightlyRaised';
    }
    return 'High';
  }
  if (systolic < 70 && diastolic < 40) {
    return 'Low';
  }
  return 'High';
};

/**
 *
 * @param value pressure string value
 * @returns numbered values as object
 */
export const parsePressure = (value: string) => {
  const [systolic, diastolic] = value.split('/').map(Number);
  return { systolic, diastolic };
};
