/* eslint-disable camelcase */
import React from 'react';
import BiologicalResult from './BiologicalResult/BiologicalResult';
import BiologicalResultsProps from '.';
import Container from './styles';

function BiologicalResults({
  totalCholesterol = { thresholdExceeded: false },
  hdl = { thresholdExceeded: false },
  nonHdl = { thresholdExceeded: false },
  ldl = { thresholdExceeded: false },
  totalHdlRatio = { thresholdExceeded: false },
  triglycerides = { thresholdExceeded: false },
  isFemale = undefined,
  hadCardiovascularDisease = undefined,
}: BiologicalResultsProps) {
  return (
    <Container>
      <BiologicalResult
        resultType="totalCholesterol"
        value={totalCholesterol.value}
        thresholdExceeded={totalCholesterol.thresholdExceeded}
      />
      <BiologicalResult
        resultType="hdl"
        value={hdl.value}
        isFemale={isFemale}
        thresholdExceeded={hdl.thresholdExceeded}
      />
      <BiologicalResult
        resultType="nonHdl"
        value={nonHdl.value}
        thresholdExceeded={nonHdl.thresholdExceeded}
        hadCardiovascularDisease={hadCardiovascularDisease}
      />
      <BiologicalResult
        resultType="ldl"
        ldlNotCalculated={
          hdl.thresholdExceeded
          || totalCholesterol.thresholdExceeded
          || triglycerides.thresholdExceeded
        }
        value={ldl.value}
        thresholdExceeded={ldl.thresholdExceeded}
        hadCardiovascularDisease={hadCardiovascularDisease}
      />
      <BiologicalResult
        resultType="totalHdlRatio"
        value={totalHdlRatio.value}
        thresholdExceeded={totalHdlRatio.thresholdExceeded}
      />
      <BiologicalResult
        resultType="triglycerides"
        value={triglycerides.value}
        thresholdExceeded={triglycerides.thresholdExceeded}
      />
    </Container>
  );
}

export default BiologicalResults;
