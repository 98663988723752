const Main = {
  Blood: '#DD3138',
  Primary: '#5572CC',
  Dark: '#2C2C2C',
  Light: '#FFFFFF',
  Background: '#FAFAFA',
} as const;

const Outline = {
  Portal: '#F6F6F6',
  Blue: '#5572CC',
  Element: '#EAEAEA',
} as const;

const Status = {
  Successful: '#158374',
  Warning: '#F1C961',
  Error: '#DD3138',
  Pending: '#5572CC',
} as const;

const Action = {
  Default: '#5572CC',
  Active: '#597AE2',
  Disabled: '#D5D5D5',
} as const;

const Icon = {
  Active: '#5572CC',
  Inactive: '#D5D5D5',
  Disabled: '#EAEAEA',
  Error: '#DD3138',
} as const;

const Text = {
  Default: '#757575',
  Disabled: '#D5D5D5',
  Heading: '#565656',
  Paragraph: '#595959',
  Link: '#5572CC',
} as const;

const BiologicalResultColors = {
  LowBackground: '#dedfff',
  HealthyBackground: '#EEF7F2',
  UnhealthyBackground: '#FFEEEE',
  NonAvailableBackground: '#FAFAFA',
  WarningBackground: '#FFF6DE',
};

const PressureGraph = {
  Low: '#595981',
  Healthy: '#6c9883',
  SlightlyRaised: '#fbd240',
  High: '#f56c51',
};

const TestResultsRowBackground = '#F0F0F0';

const Colors = {
  Main,
  Outline,
  Status,
  Action,
  Icon,
  Text,
  BiologicalResultColors,
  TestResultsRowBackground,
  PressureGraph,
};

export default Colors;
