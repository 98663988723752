import React from 'react';
import { Container, Line, TopLine, PressureValuesContainer, PressureValues } from './styles';
import { BloodPressureGraphProps } from '..';

function PatientPressure({ status, systolic, diastolic }: BloodPressureGraphProps) {
  return (
    <Container diastolic={diastolic} systolic={systolic}>
      <Line style={{ transform: 'rotate(45deg)' }} status={status} />
      <Line style={{ transform: 'rotate(135deg)' }} status={status} />
      <TopLine style={{ transform: 'rotate(45deg)' }} status={status} />
      <TopLine style={{ transform: 'rotate(135deg)' }} status={status} />
      <PressureValuesContainer status={status}>
        <PressureValues>{`${systolic}/${diastolic}`}</PressureValues>
      </PressureValuesContainer>
    </Container>
  );
}

export default PatientPressure;
