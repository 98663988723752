import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Colors from '../../../../../../theme/colors';
import { StatusLabelProps, BloodPressureStatus, BloodPressureContainerProps } from '..';
import { graphsDimensions } from './const';

const BloodPressureContainedBackgroundColors = {
  Low: Colors.BiologicalResultColors.WarningBackground,
  Healthy: Colors.BiologicalResultColors.HealthyBackground,
  SlightlyRaised: Colors.BiologicalResultColors.WarningBackground,
  High: Colors.BiologicalResultColors.UnhealthyBackground,
};

export const StatusBgColor = {
  Low: Colors.Status.Pending,
  Healthy: Colors.Status.Successful,
  SlightlyRaised: Colors.Status.Warning,
  High: Colors.Main.Blood,
};

export const StatusTextColor = {
  Low: Colors.Main.Dark,
  Healthy: Colors.Main.Light,
  SlightlyRaised: Colors.Main.Dark,
  High: Colors.Main.Light,
};

const Container = styled(Box)<BoxProps & BloodPressureContainerProps>(({ status }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: graphsDimensions.width,
  height: graphsDimensions.height,
  margin: '40px',
}));

const Title = styled(Typography)({
  flex: 39,
  display: 'flex',
  alignItems: 'center',
});

Title.defaultProps = {
  variant: 'BodySmallRegular',
  color: Colors.Text.Heading,
};

const Cell = styled(Box)({
  width: '16.66%',
  height: '100%',
  borderColor: 'white',
  borderWidth: '1px',
  backgroundColor: 'green',
  borderStyle: 'solid',
  position: 'relative',
});

const SlightlyRaisedCell = styled(Box)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: Colors.PressureGraph.SlightlyRaised,
  bottom: 0,
});

const Row = styled(Box)({
  display: 'flex',
  width: '100%',
  height: '10%',
});

const ValueS = styled(Typography)({ position: 'absolute', left: '-50%', top: '-40%' });
const ValueD = styled(Typography)({ position: 'absolute', right: '-13%', bottom: '-100%' });
const StartingValueS = styled(Typography)({
  position: 'absolute',
  bottom: '-10px',
  left: '-39px',
  zIndex: 1,
});
const StartingValueD = styled(Typography)({
  position: 'absolute',
  bottom: '-34px',
  left: '-10px',
  zIndex: 1,
});
export { Container, Cell, Row, SlightlyRaisedCell, ValueD, ValueS, StartingValueS, StartingValueD };
