import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Colors from '../../../../../../../theme/colors';
import { BloodPressureStatus } from '../..';
import { graphsDimensions } from '../const';

export const StatusBgColor = {
  Low: Colors.Status.Pending,
  Healthy: Colors.Status.Successful,
  SlightlyRaised: Colors.Status.Warning,
  High: Colors.Main.Blood,
};

export const StatusTextColor = {
  Low: Colors.Main.Dark,
  Healthy: Colors.Main.Light,
  SlightlyRaised: Colors.Main.Dark,
  High: Colors.Main.Light,
};

const getPressureColor = (status: BloodPressureStatus) => {
  if (status === 'Healthy') {
    return Colors.PressureGraph.Healthy;
  }
  if (status === 'Low') {
    return Colors.PressureGraph.Low;
  }
  if (status === 'SlightlyRaised') {
    return Colors.PressureGraph.SlightlyRaised;
  }
  return Colors.PressureGraph.High;
};

const getPositionOnGraph = (
  value: number,
  minValue: number,
  maxValue: number,
  dimensionSize: number
) => {
  return ((value - minValue) / (maxValue - minValue)) * dimensionSize;
};

const size = 30;

const getSystolicValue = (systolic: number) => {
  if (systolic >= 70 && systolic <= 170) return systolic;
  if (systolic < 70) return 70;
  return 170;
};

const getDiastolicValue = (diastolic: number) => {
  if (diastolic >= 40 && diastolic <= 100) return diastolic;
  if (diastolic < 40) return 40;
  return 100;
};

const Container = styled(Box)<BoxProps & { diastolic: number; systolic: number }>(
  ({ diastolic, systolic }) => ({
    height: size,
    width: size,
    position: 'absolute',
    zIndex: 999,
    bottom:
      getPositionOnGraph(getSystolicValue(systolic), 70, 170, graphsDimensions.height) - size / 2,
    left:
      getPositionOnGraph(getDiastolicValue(diastolic), 40, 100, graphsDimensions.width) - size / 2,
    justifyContent: 'center',
    alignItems: 'center',
  })
);

const Line = styled(Box)<BoxProps & { status: BloodPressureStatus }>(({ status }) => ({
  position: 'absolute',
  width: size,
  height: 8,
  top: 10,
  backgroundColor: getPressureColor(status),
  outline: 'solid',
  outlineColor: 'white',
}));

const TopLine = styled(Box)<BoxProps & { status: BloodPressureStatus }>(({ status }) => ({
  position: 'absolute',
  width: size,
  height: 8,
  top: 10,
  backgroundColor: getPressureColor(status),
}));

const PressureValuesContainer = styled(Box)<BoxProps & { status: BloodPressureStatus }>(
  ({ status }) => ({
    position: 'absolute',
    width: 70,
    height: 30,
    top: -1,
    left: 40,
    outline: 'solid',
    outlineColor: 'white',
    backgroundColor: getPressureColor(status),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })
);

const PressureValues = styled(Typography)({ color: 'white' });

export { Container, Line, TopLine, PressureValuesContainer, PressureValues };
