import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Colors from '../../../../../theme/colors';
import { StatusLabelProps, BloodPressureContainerProps } from '.';
import { graphsDimensions } from './BloodPressureGraph/const';

const BloodPressureContainedBackgroundColors = {
  Low: Colors.BiologicalResultColors.WarningBackground,
  Healthy: Colors.BiologicalResultColors.HealthyBackground,
  SlightlyRaised: Colors.BiologicalResultColors.WarningBackground,
  High: Colors.BiologicalResultColors.UnhealthyBackground,
  OutOfRange: Colors.BiologicalResultColors.UnhealthyBackground,
};

export const StatusBgColor = {
  Low: Colors.Status.Warning,
  Healthy: Colors.Status.Successful,
  SlightlyRaised: Colors.Status.Warning,
  High: Colors.Main.Blood,
  OutOfRange: Colors.Main.Blood,
};

export const StatusTextColor = {
  Low: Colors.Main.Dark,
  Healthy: Colors.Main.Light,
  SlightlyRaised: Colors.Main.Dark,
  High: Colors.Main.Light,
  OutOfRange: Colors.Main.Light,
};

const Container = styled(Box)<BoxProps & BloodPressureContainerProps>(({ status }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  minHeight: '88px',
  padding: ['12px', '24px'],
  margin: ['4px', '0px'],
  marginTop: '8px',
  borderRadius: '10px',
  borderStyle: 'solid',
  borderWidth: '2px',
  backgroundColor: BloodPressureContainedBackgroundColors[status],
  borderColor: StatusBgColor[status],
}));

const StatusHeader = styled(Box)({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
});

const TitleContainer = styled(Box)({
  flex: 39,
  display: 'flex',
});

const Title = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  marginRight: 8,
});
const Unit = styled(Typography)({});

Title.defaultProps = {
  variant: 'BodySmallRegular',
  color: Colors.Text.Heading,
};

const StatusContainer = styled(Typography)({
  flex: 28,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
});
const GraphContainer = styled(Box)({
  width: graphsDimensions.width * 1.4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 20,
});

const GraphValues = styled(Typography)(() => ({}));

const StatusLabel = styled(Typography)<TypographyProps & StatusLabelProps>((props) => ({
  padding: '10px',
  height: '26px',
  minWidth: '86px',
  backgroundColor: StatusBgColor[props.status],
  color: StatusTextColor[props.status],
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'capitalize',
}));

StatusLabel.defaultProps = {
  variant: 'BodySmallBoldPoppins',
};

export {
  Container,
  Title,
  TitleContainer,
  Unit,
  GraphContainer,
  StatusContainer,
  StatusLabel,
  StatusHeader,
  GraphValues,
};
