import React from 'react';
import {
  Cell,
  Container,
  Row,
  SlightlyRaisedCell,
  StartingValueD,
  StartingValueS,
  ValueD,
  ValueS,
} from './styles';
import Colors from '../../../../../../theme/colors';
import { BloodPressureGraphProps } from '.';
import PatientPressure from './PatientPressure/PatientPressure';

const getCellColor = (cellS: number, cellD: number) => {
  if (cellS <= 90 && cellD <= 60) {
    return Colors.PressureGraph.Low;
  }
  if (cellS <= 120 && cellD <= 80) {
    return Colors.PressureGraph.Healthy;
  }
  return Colors.PressureGraph.High;
};

const getConsSlightlyRaisedCellStyle = (cellS: number, cellD: number, isPro: boolean) => {
  if (cellS === 130 && cellD < 90) {
    return { height: '100%', width: '100%' };
  }
  if (cellS === 140 && cellD < 90) {
    return !isPro ? { height: '50%', width: '100%' } : { height: '100%', width: '100%' };
    // return styles.highPressureAreaSHalfBottom;
  }
  if (cellS === 140 && cellD === 90) {
    return !isPro ? { height: '50%', width: '50%' } : { height: '100%', width: '100%' };
    // return styles.highPressureAreaSQuarter;
  }
  if (cellS < 140 && cellD === 90) {
    return !isPro ? { height: '100%', width: '50%' } : { height: '100%', width: '100%' };
    // return styles.highPressureAreaSHalfSide;
  }
  return { height: '0px', width: '0px' };
};

// const getHCPSlightlyRaisedCellStyle = (cellS: number, cellD: number) => {
//   if (cellS === 130 && cellD < 90) {
//     return styles.highPressureAreaSFull;
//   }
//   if (cellS === 140 && cellD < 90) {
//     return styles.highPressureAreaSHalfBottom;
//   }
//   if (cellS === 140 && cellD === 90) {
//     return styles.highPressureAreaSQuarter;
//   }
//   if (cellS < 140 && cellD === 90) {
//     return styles.highPressureAreaSHalfSide;
//   }
// };
const systolicValues = [170, 160, 150, 140, 130, 120, 110, 100, 90, 80];
const diastolicValues = [50, 60, 70, 80, 90, 100];

function BloodPressureGraph({ systolic, diastolic, status, testType }: BloodPressureGraphProps) {
  const isPro = testType === 'pro';
  return (
    <Container status={status}>
      <StartingValueD>{40}</StartingValueD>
      <StartingValueS>{70}</StartingValueS>
      {!isPro && <StartingValueD style={{ left: 352 }}>{85}</StartingValueD>}
      {!isPro && <StartingValueS style={{ top: 136 }}>{135}</StartingValueS>}
      <PatientPressure
        status={status}
        diastolic={diastolic}
        systolic={systolic}
        testType={testType}
      />
      {systolicValues.map((valueS) => (
        <Row key={valueS}>
          {diastolicValues.map((valueD) => (
            <Cell
              style={{ backgroundColor: getCellColor(valueS, valueD) }}
              key={`${valueS}/${valueD}`}
            >
              <SlightlyRaisedCell
                style={{ ...getConsSlightlyRaisedCellStyle(valueS, valueD, isPro) }}
              />
              <ValueS>
                {valueD === 50 &&
                  (valueS === 90 || valueS === 120 || valueS === 140 || valueS === 170) &&
                  valueS}
              </ValueS>
              <ValueD>
                {valueS === 80 &&
                  (valueD === 60 || valueD === 80 || valueD === 90 || valueD === 100) &&
                  valueD}
              </ValueD>
            </Cell>
          ))}
        </Row>
      ))}
    </Container>
  );
}

export default BloodPressureGraph;
