import React from 'react';
import { getGraphParams, getStatus } from '../../../../../utilities/hbA1cUtils';
import { HbA1CProps } from '.';
import Colors from '../../../../../theme/colors';
import { BarGraph } from '../../../../../components/BarGraph';
import {
  getGraphColors,
  Container,
  Title,
  BarGraphContainer,
  StatusContainer,
  StatusLabel,
  Unit,
  TitleContainer,
} from './styles';

function HbA1C({ hbA1cValue, isSecondaryPrevention }: HbA1CProps) {
  const status = getStatus(hbA1cValue, isSecondaryPrevention);
  const { valueLabelColor, valueLabelTextColor, colors } = getGraphColors(
    status,
    isSecondaryPrevention
  );
  const { graphPoints, resultLabel, resultValue } = getGraphParams(
    hbA1cValue,
    isSecondaryPrevention
  );
  const graphValue = {
    point: resultValue,
    label: resultLabel,
    bgColor: valueLabelColor,
    labelColor: valueLabelTextColor,
  };
  return (
    <Container status={status}>
      <TitleContainer>
        <Title color={Colors.Text.Heading} variant="BodyMediumSemiBold">
          HbA1c
        </Title>
        <Unit color={Colors.Text.Heading} variant="BodyMediumRegular">{`(%)`}</Unit>
      </TitleContainer>

      <BarGraphContainer>
        <BarGraph
          value={graphValue}
          colors={colors}
          showFirstAndLastLabel={false}
          points={graphPoints}
        />
      </BarGraphContainer>
      <StatusContainer>
        <StatusLabel status={status}>{status}</StatusLabel>
      </StatusContainer>
    </Container>
  );
}

export default HbA1C;
